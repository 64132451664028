<template>
	<div class="c_content_container contact">
		<!-- 상단배너 START -->
		<section class="c_section c_banner banner_about">
			<div class="c_inner">
				<div class="img_wrapper about">
					<div class="icon_wrapper">
						<img src="@/assets/images/banner/icon_about.png" alt="아이콘" class="icon" />
					</div>
				</div>
			</div>
		</section>
		<!-- 상단배너 END -->

		<section class="c_section ci_content">
			<div class="c_inner">
				<div class="clients">
					<h1 class="section_title">{{ $t("Clients") }}</h1>
					<p class="section_text">
						{{ $t("Clients Pg Txt") }}
					</p>
					<div class="ci_wrap">
						<div class="image_wrap" v-for="i in data" :key="i">
							<img :src="require(`@/assets/images/about/clients_ci/client_ci_${i}-${nowMode}.svg`)" />
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
	setup() {
		const store = useStore();
		const nowMode = computed(() => store.state.isLightMode);

		const data=[
			'cj',
			'mugunghwa',
			'kt',
			'lotte',
			'sk',
			'danal',
			'samsung',
			'kakao',
			'kp-pay',
			'weareowner',
			'good-printing',
			'eum',
			'barogo',
			'kfo',
			'remember',
			't-onpay',
			'humanvalue',
			'korbill',
			'unify',
		]

		return {
			nowMode,
			data
		};
	},
};
</script>

<style scoped src="@/assets/css/about/clients.css"></style>
